<template>
  <div class="card-list">
    <!-- <ul> -->
    <ul :style="listLength">
      <li v-for="(card, index) in cards" :key="index" :style="listPosition">
        <card :item="card" :active="index == currentIndex"/>
        
        <!-- <div style="position:absolute; top:0; background:green; color: white; padding:15px;">
            CurrentIndex {{ currentIndex }} <br />
            endX {{ touch.endX }} <br />
            startX {{ touch.startX }} <br />

        </div> -->
      </li>
    </ul>
    <a class="button" style="position:absolute; top: 300px" v-on:click="prevIndex()">
        <i class="fa fa-chevron-left"></i>
    </a>
    <a class="button" style="position:absolute; top: 300px; right:30px" v-on:click="nextIndex()">
        <i class="fa fa-chevron-right"></i>
    </a>
  </div>
</template>

<script>
//import Modal from '../components/Modal.vue'
import Card from './Card'

 /* var gallery =  [
            { "id":1, 'title':'Title 1', 'description':'description 1', 'url': "https://dataczar-public.s3.us-west-2.amazonaws.com/photos/1/Z2ULNR5_Sp1a7.jpg" },
            { "id":2, 'title':'Title 2', 'description':'description 2', 'url': "https://dataczar-public.s3.us-west-2.amazonaws.com/photos/1/download_lPtjC.jpeg" },
            { "id":3, 'title':'Title 3', 'description':'description 3', 'url': "https://dataczar-public.s3.us-west-2.amazonaws.com/photos/1/m5yp_mreldritch_dc_M4T5s.jpg" },
        ];  */
        //var gallery = [{ "url": "https://dataczar-public.s3.us-west-2.amazonaws.com/photos/1/vLN1Oro_Umrqh.png", "title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." }, { "title": "NewItem", "description": "NewItem", "url": "https://dataczar-public.s3.us-west-2.amazonaws.com/photos/1/70_Stylel-Is-Fit.jpg" }]
    

export default {
    name: "CardList",
    components: {
        Card
        //Modal
    },
    data() {
        return {
            touch: {
                startX: 0,
                endX: 0,
            },
            currentIndex: 0,
            cards: gallery,
        };
    },
    computed: {
        listLength() {
            return { width: this.cards.length * 100 + '%' }; 
        },
        listPosition() {
            return {transform: 'translateX(-' + this.currentIndex * 100 + '%)'}
        }
    }, 
    methods: {
        touchStart(event) {
            this.touch.startX = event.touches[0].clientX;
            this.touch.endX = 0;
        },
        touchMove(event) {
            this.touch.endX = event.touches[0].clientX;
        },
        touchEnd() {
            if(!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20) {
                return;
            }
            if(this.touch.endX < this.touch.startX) {
                this.nextIndex();
            } else {
                this.prevIndex();
            }
        },
        nextIndex() {
            if (this.currentIndex < this.cards.length -1) {
                this.currentIndex++;
            }
        },
        prevIndex() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
       /*  getRecords() {
            axios
                .get('data/slider.json')
                .then(response => (this.cards = response.data))
        } */
    },
    mounted() {
        /* this.getRecords(); */
        this.$el.addEventListener('touchstart', event => this.touchStart(event));
        this.$el.addEventListener('touchmove', event => this.touchMove(event));
        this.$el.addEventListener('touchend', () => this.touchEnd());
    }
};
</script>

<style lang="scss" scoped>
    .card-list {
        margin: 0 30px;
        overflow:hidden;

        ul {
            display: flex;
            list-style: none;
        }

        li {
            width: 100%; 
            padding: 10px;
            transition: all .5s ease;
        }
    }
</style>