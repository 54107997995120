<template>
<div class="cardItem">
    <div class="img" :style="backgroundImage"></div>
    <div class="title" v-if="item.title">{{ item.title }}</div>
    <div class="description" v-if="item.description">{{ item.description }}</div>
</div>
    
</template>

<script>
export default {
   props: [
       'item', 
       'active'
    ],
    computed: {
        backgroundImage() {
            return { 'background-image': 'url(' + this.item.url  + ')'}
        }
    },
    methods: {

    },
    mounted() {
        //console.log(this.item);
    }, 
}
</script>

<style lang="scss" scoped>
    .cardItem {
        position: relative;
        width: 100%;
        height: 600px;
        background-color:slategray;
        overflow: hidden;

        .img {
            width: 100%;
            height: 600px;
            background-position: center;
            background-size: cover;
        }

        .title {
            position: absolute;
            top: 50px;
            padding: 5px 20px;
            width: calc(100% - 20px);
            /* height: 40px; */
            font-size: 1.6rem;
            color: #FFF;
            background-color: rgba($color: #000, $alpha: .5);
        }

        .description {
            position: absolute;
            bottom: 50px;
            padding: 20px;
            color: white;
            background-color: rgba($color: #000, $alpha: .5);
        }
    }

</style>